import React, { useState, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./index.scss";
import LogoIcon from "../../assets/icon/logo-icon.png";
import PhoneIcon from "../../assets/icon/phone-icon.png";
import MailBox from "../../assets/icon/mailbox-icon.png";
const Head = () => {
  const history = useHistory();
  const location = useLocation();
  const urlList = [
    { id: 1, url: "/", name: "首页" },
    { id: 2, url: "/cases", name: "案例" },
    { id: 5, url: "http://shop.tongtanggift.com/", name: "商城" },
    { id: 3, url: "/about_us", name: "我们" },
    { id: 4, url: "/join", name: "加入" },
  ];
  const [UrlIndex, setUrlIndex] = useState(1);
  const [show, setShow] = useState(true);
  window.addEventListener("scroll", (e) => {
    if (window.scrollY + 150 > window.innerHeight && show) {
      setShow(false);
    } else if (window.scrollY <= window.innerHeight - 150 && !show) {
      setShow(true);
    }
  });
  const tabValue = useMemo(() => {
    if (location.pathname.startsWith("/home")) {
      setUrlIndex(1);
      return 1;
    } else if (location.pathname.startsWith("/cases")) {
      setUrlIndex(2);
      return 2;
    } else if (location.pathname.startsWith("/about_us")) {
      setUrlIndex(3);
      return 3;
    } else if (location.pathname.startsWith("/join")) {
      setUrlIndex(4);
      return 4;
    } else if (location.pathname.startsWith("/")) {
      setUrlIndex(1);
      return 1;
    }
  }, [location.pathname]);
  const goRouter = (item) => {
    if (item.name == "商城") {
      window.open(`${item.url}`);
    } else {
      setUrlIndex(item.id);
      history.push(`${item.url}`);
    }
  };
  return (
    <div
      className="head"
      style={{
        opacity: show ? 1 : 0,
        top: show ? 0 : "-81px",
        transition: "all 0.25s ease-in-out",
      }}
    >
      <div className="head-content">
        <div className="head-content-left">
          <div className="head-content-logo">
            <img src={LogoIcon} alt="" />
          </div>
          <div className="head-content-url">
            {urlList.map((item, index) => {
              return (
                <div
                  className={"url-item"}
                  //style={{ color: tabValue == 1 ? "#fff" : "#000" }}
                  key={index}
                  onClick={() => goRouter(item)}
                >
                  <div
                    className={
                      item.id == UrlIndex
                        ? "url-item-title url-item-title-active"
                        : "url-item-title"
                    }
                  >
                    {item.name}
                  </div>

                  {(index + 1) < urlList?.length && (
                    <span className="url-item-tips">/</span>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        <div className="head-content-tips">
          <div className="tips-phone">
            <div className="tips-phone-icon">
              <img src={PhoneIcon} alt="" />
            </div>
            <div
              className="tips-phone-title"
              //style={{ color: tabValue == 1 ? "#fff" : "#000" }}
            >
              400-888-3905
            </div>
          </div>
          <div className="tips-mail">
            <div className="tips-mail-icon">
              <img src={MailBox} alt="" />
            </div>
            <div className="tips-mail-title">market@hxtongtang.com</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Head;
