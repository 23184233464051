import { Box } from "@mui/material";
import R from "../../../resources/Namespace";
import ImageCard from "./ImageCard";

function MeetingAreaImages() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        marginLeft: "76px",
      }}
    >
      {/* <Box sx={{
                display: "flex",
                flexDirection: "column",
                flex: "0 0 1336px",
            }}>
                <ImageCard
                    width="1336px"
                    height="580px"
                    title={`${R.strings.join_us.office.meeting_area}`}
                    url="/join_us/office/meeting@2x.png"
                />
            </Box> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: "0 0 424px",
        }}
      >
        <ImageCard
          width="424px"
          height="580px"
          title={`${R.strings.join_us.office.meeting_area}-01`}
          url="/join_us/office/meeting1.png"
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: "0 0 880px",
          marginLeft: "32px",
        }}
      >
        <ImageCard
          width="880px"
          height="580px"
          title={`${R.strings.join_us.office.meeting_area}-02`}
          url="/join_us/office/meeting2.png"
        />
      </Box>
    </Box>
  );
}

export default MeetingAreaImages;
