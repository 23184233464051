/*
 * @Author: yc 422874523@qq.com
 * @Date: 2023-02-12 11:15:13
 * @LastEditors: yc 422874523@qq.com
 * @LastEditTime: 2023-02-12 11:43:43
 * @FilePath: \site\tongtang-website\src\pages\Home\components\SelectCase\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React,{useState} from "react";
import "./index.scss";
import { useHistory } from "react-router-dom";
import selectCaseIcon from "../../../../assets/icon/selectCase-icon.png";
import MoreIcon from "../../../../assets/icon/more.png";
import MoreHoverIcon from "../../../../assets/icon/more-hover.png";
import CasesBack from "../../../../assets/icon/cases.png";
import { ossUrl } from "../../../../ossUrl";
const urlOss = "https://images.tongtanggift.com/website/home/new_img/";
const SelectCase = () => {
  const history = useHistory();
  const [isMore,setIsMore]=useState(false);
  return (
    <div className="selectCase">
      {/* <div className="selectCase-content" onClick={()=>{history.push('/cases')}}>
                <div className="selectCase-content-item1">
                    <div className="item1-top">
                        <img src={`${urlOss}item1-top.png${ossUrl()}`} alt="" id="item1Url" />
                        <div className="item1-selectCase-icon">
                            <img src={selectCaseIcon} alt="" />
                        </div>
                    </div>
                    <div className="item1-footer">
                        <div className="item1-footer-left">
                            <img src={`${urlOss}item1-footer-left.png${ossUrl()}`} alt=""/>
                        </div>
                        <div className="item1-footer-right">
                            <img src={`${urlOss}item1-footer-right.png${ossUrl()}`} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="selectCase-content-item2">
                    <div className="item2-top">
                        <div className="item2-top-left">
                            <img src={`${urlOss}item2-top-left.png${ossUrl()}`} alt=""/>
                        </div>
                        <div className="item2-top-right">
                            <div className="item2-top-right1">
                                <img src={`${urlOss}item2-top-right1.png${ossUrl()}`} alt=""/>
                            </div>
                            <div className="item2-top-right2">
                                <img src={`${urlOss}item2-top-right2.png${ossUrl()}`} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="item2-footer">
                        <img src={`${urlOss}item2-footer.png${ossUrl()}`} alt=""/>
                    </div>
                </div>
                <div className="selectCase-content-item3">
                    <div className="item3-top">
                        <img src={`${urlOss}item3-top.png${ossUrl()}`} alt=""/>
                    </div>
                    <div className="item3-footer">
                        <img src={`${urlOss}item3-footer.png${ossUrl()}`} alt="" id="item3Url"/>
                    </div>
                </div>
            </div> */}
      <div className="selectCase-top">
        <div className="selectCase-top-icon">
          <img src={selectCaseIcon} alt="" />
        </div>
      </div>
      <div className="selectCase-list">
        <div className="selectCase-list-top">
          <div className="top-item1">
            <img src={`${urlOss}top1.png${ossUrl()}`} alt="" />
          </div>
          <div className="top-item2">
            <img src={`${urlOss}top2.png${ossUrl()}`} alt="" />
          </div>
          <div className="top-item3">
            <img src={`${urlOss}top3.png${ossUrl()}`} alt="" />
          </div>
        </div>
        <div className="selectCase-list-center">
          <div className="center-item1">
            <img src={`${urlOss}center1.png${ossUrl()}`} alt="" />
          </div>
          <div className="center-item2">
            <img src={`${urlOss}center2.png${ossUrl()}`} alt="" />
          </div>
          <div className="center-item3">
            <img src={`${urlOss}center3.png${ossUrl()}`} alt="" />
          </div>
        </div>
        <div className="selectCase-list-foot">
          <div className="foot-item1">
            <img src={`${urlOss}foot1.png${ossUrl()}`} alt="" />
          </div>
          <div className="foot-item2">
            <img src={`${urlOss}foot2.png${ossUrl()}`} alt="" />
          </div>
          <div className="foot-item3">
            <img src={`${urlOss}foot3.png${ossUrl()}`} alt="" />
          </div>
        </div>
      </div>
      <div className="selectCase-footer">
        <div
          className={isMore?"selectCase-footer-icon selectCase-footer-icon-active":"selectCase-footer-icon"}
          onClick={() => {
            history.push("/cases");
          }}
          onMouseEnter={()=>{setIsMore(true)}}
          onMouseLeave={()=>{setIsMore(false)}}
        >
          {/* <img src={isMore?MoreHoverIcon:MoreIcon} alt="" /> */}
        </div>
      </div>
      <div className="selectCase-back">
        <img src={CasesBack} alt="" />
      </div>
    </div>
  );
};
export default SelectCase;
