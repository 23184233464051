import { Box } from "@mui/material";
import ContentDetail from "./ContentDetail";
import Footer from "../../components/Footer";

function DetailUs() {

    return (
        <Box display="flex" flexDirection="column" sx={{
            width: "100%",
            flex: "1 1 auto",
            alignItems: "center",
        }}>
            <ContentDetail />
            <Footer />
        </Box>
    )
}

export default DetailUs;