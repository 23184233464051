import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../../components/Footer";
import ClientWall from "./ClientWall";
import ContactUs from "./ContactUs";
import CreativeDesign from "./CreativeDesign";
import CultureDerivative from "./CultureDerivative";
import Reserve from "./Reserve";
import Section1 from "./Section1";
import SolarTerms from "./SolarTerms";
import WholeBox from "./WholeBox";
import WholeBoxs from "./WholeBoxs";
function Cases() {
  const location = useLocation();
  const [innerWidth, setInnerWidth] = useState(0);
  useEffect(() => {
    setInnerWidth(window.innerWidth);
    function watchWindowSize() {
      setInnerWidth(window.innerWidth);
    }
    window.addEventListener("resize", watchWindowSize);
    return () => window.addEventListener("resize", watchWindowSize);
  }, [window.innerWidth]);
  useEffect(() => {
    if (location.hash === "#whole_box_section") {
      document.querySelector("#whole_box_section")?.scrollIntoView({
        behavior: "smooth",
      });
    } else if (location.hash === "#client_wall") {
      document.querySelector("#client_wall")?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        width: "100%",
        flex: "1 1 auto",
        zoom: innerWidth >= 1920 ? 1 : 0.70,
      }}
    >
      <Section1 />
      <WholeBoxs />
      <Reserve />
      <CultureDerivative />
      <CreativeDesign />
      <SolarTerms />
      <ContactUs />
      <Footer />
    </Box>
  );
}

export default Cases;
