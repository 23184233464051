import {
    Box, Grid,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    Paper,
    Typography
} from "@mui/material";
import StarIcon from '@mui/icons-material/Star';
import {getImageUrl} from "../../helpers/urlHelper";
import {
    AccountBox,
    AssignmentTurnedIn,
    Business, CheckCircle,
    Group,
    Money,
    School
} from "@mui/icons-material";
function DrawBox(){
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            background: "#F7F7F7",
            alignItems: "center",
        }}>
            <Box sx={{
                display: "flex",
                width: "80%",
                marginTop:'70px'
            }}>
                <Paper square>
                    <Typography  variant="h3" color="#252525" margin="50px">
                        简介
                    </Typography>
                    <List sx={{margin:'30px'}} aria-label="contacts">
                        <ListSubheader sx={{fontSize:'30px'}}>
                            北京春湘科技有限公司成立于2018年，是国内领先的企业礼品定制服务商。<br/>
                            <CheckCircle/>2019年推出自有品牌“铜棠有礼”。<br/>
                            <CheckCircle/>2021年在上海、深圳分别设立分公司。<br/>
                            <CheckCircle/>2022年礼品定制商城“一点礼至”正式上线。<br/>
                            <br/>
                            官网地址：<a href="https://www.tongtanggift.com">https://www.tongtanggift.com</a><br/>
                            官方热线：400-888-3005<br/>
                            邮箱：<a href="mailto:market@hxtongtang.com">market@hxtongtang.com</a>
                        </ListSubheader>
                    </List>
                    <Typography  variant="h3" color="#252525" margin="25px">
                        旗下品牌
                    </Typography>
                    <List sx={{margin:'30px'}} aria-label="contacts">
                        <ListSubheader sx={{fontSize:'30px'}}>
                            <StarIcon /><b>"铜棠有礼"</b>是北京春湘科技的线下业务品牌，专注于为KA级企业客户提供全方位的企业礼品解决方案。业务范围覆盖IP定制产品、节日福利礼盒、日常营销礼品、非遗文化创意设计等全案定制及仓储物流配送服务等。以丰富的定制礼品产品线、高效完善的服务流程、强大的供应链体系、以及优质的设计能力，服务了包括字节跳动、快手、百度、得物、喜马拉雅、光大银行、邦泰等在内的众多世界500强企业及行业头部客户，覆盖互联网、IT、金融、游戏、地产等不同行业。<br/>
                            <Box sx={{
                                width: "380px",
                                height: "163px",
                                backgroundImage: `url(${getImageUrl("/about_us/detail_us/tongtang_img.png")})`,
                                backgroundSize: "contain",
                                marginBottom: "32px",
                            }} />
                            <StarIcon /><b>"一点礼至"</b>是北京春湘科技的线上业务品牌，专注于通过数字化平台的手段为广大中小型企业客户，提供丰富、快捷、高效在线礼品定制服务。汇聚海量的自营定制产品，品类齐全，基本覆盖国内目前可支持定制的所有品类商品，包括服装、工艺品、印刷品、数码3C等。使用场景覆盖员工关怀、企业周边、活动物资、商务礼赠等多种企业用途，可实现在线DIY、准确核价、快速下单、精准发货、框架结算等采购全流程数字化能力。
                            <Box sx={{
                                width: "390px",
                                height: "105px",
                                backgroundImage: `url(${getImageUrl("/about_us/detail_us/yidianlizhi_img.png")})`,
                                backgroundSize: "contain",
                                marginBottom: "32px",
                            }} />
                        </ListSubheader >
                    </List>
                    <Typography  variant="h3" color="#252525" margin="25px">
                        公司规模
                    </Typography>
                    <List sx={{margin:'30px'}} aria-label="contacts">
                        <ListSubheader sx={{fontSize:'30px'}}>
                            <Business />公司业务在不断的快速发展下，逐步成立设计中心、供应链管理中心、营销中心、产品中心、综合管理中心等，2021年在上海、深圳分别设立分公司。基于企业礼品定制，实现企业服务深度定制化，提供一站式的全案定制解决方案。<br/>
                            <AccountBox />员工数量50+<br/>
                            <Group />服务客户150+<br/>
                            <School />知识产权7+<br/>
                            <Money />2021年营业额1亿
                        </ListSubheader >
                    </List>
                    <Typography  variant="h3" color="#252525" margin="25px">
                        优势能力
                    </Typography>
                    <List sx={{margin:'30px'}} aria-label="contacts">
                        <ListSubheader>
                            <ListItem>
                                <ListItemText primary={
                                    <Typography variant="h4">
                                        <AssignmentTurnedIn />专业出众的设计能力
                                    </Typography>
                                } secondary={<Typography variant="h5">
                                &nbsp;&nbsp;&nbsp;&nbsp;汇聚众多优秀设计师，设计团队曾获德国红点奖，为企业客户提供专属的创意设计方案提供专业能力保障。<br/>
                                </Typography>
                                }/>
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={
                                    <Typography variant="h4">
                                        <AssignmentTurnedIn />响应及时的客户服务
                                    </Typography>
                                } secondary={<Typography variant="h5">
                                    &nbsp;&nbsp;&nbsp;&nbsp;已通过ISO9001质量体系认证、售后服务体系认证等，同时设立客户服务团队，以标准、专业的服务流程，为客户提供响应及时的星级管家服务。
                                </Typography>
                                }/>
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={
                                        <Typography variant="h4">
                                            <AssignmentTurnedIn />多元化的供应链体系
                                        </Typography>
                                    } secondary={<Typography variant="h5">
                                    &nbsp;&nbsp;&nbsp;&nbsp;建立了遍布全国的供应商体系，并在多家生产企业注资，保障生产供应的及时性与稳定性。
                                </Typography>
                                }/>
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={
                                        <Typography variant="h4">
                                            <AssignmentTurnedIn />完善的仓储配送服务
                                        </Typography>
                                    } secondary={<Typography variant="h5">
                                    &nbsp;&nbsp;&nbsp;&nbsp;在全国五个大区搭建了现代化供应链仓库，满足客户的存放、库存管理及配送需求。
                                </Typography>
                                }/>
                            </ListItem>
                        </ListSubheader>
                    </List>
                    <Typography  variant="h3" color="#252525" margin="25px">
                        企业愿景
                    </Typography>
                    <List sx={{margin:'30px'}} aria-label="contacts">
                        <ListSubheader sx={{fontSize:'30px'}}>
                            一个更专业的定制伙伴
                        </ListSubheader >
                    </List>
                    <Typography  variant="h3" color="#252525" margin="25px">
                        企业使命
                    </Typography>
                    <List sx={{margin:'30px'}} aria-label="contacts">
                        <ListSubheader sx={{fontSize:'30px'}}>
                            让每家公司都拥有专属的定制礼品
                        </ListSubheader >
                    </List>
                    <Typography  variant="h3" color="#252525" margin="25px">
                        企业价值观
                    </Typography>
                    <List sx={{margin:'30px'}} aria-label="contacts">
                        <ListSubheader sx={{fontSize:'30px'}}>
                            诚信勤俭，立德立业；<br/>
                            活跃进取，勇于担当；<br/>
                            互助学习，砥砺奋进；<br/>
                            自我驱动，实干灵活；<br/>
                            革新创造，高标高效。
                        </ListSubheader >
                    </List>
                    <Typography  variant="h3" color="#252525" margin="25px">
                        服务理念
                    </Typography>
                    <List sx={{margin:'30px'}} aria-label="contacts">
                        <ListSubheader sx={{fontSize:'30px'}}>
                            客户第一，品牌至上，一切以树立良好企业形象为根基
                        </ListSubheader>
                    </List>
                    <Typography  variant="h3" color="#252525" margin="25px">
                        企业资质
                    </Typography>

                        <Grid container spacing={3}>
                            <Grid item xs={10}>
                                <img
                                    width='110%'
                                    height='500px'
                                    src={`${getImageUrl("/about_us/detail_us/yingyezhizhao.png")}`}
                                    loading="lazy"
                                />
                            </Grid>
                            <Grid item xs={4.5}>
                                <img
                                    width='80%'
                                    height='100%'
                                    src={`${getImageUrl("/about_us/detail_us/hongdian_1.png")}`}
                                    loading="lazy"
                                />
                            </Grid>
                            <Grid item xs={5.5}>
                                <img
                                    width='80%'
                                    height='100%'
                                    src={`${getImageUrl("/about_us/detail_us/hongdian_2.png")}`}
                                    loading="lazy"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <img
                                    src={`${getImageUrl("/about_us/detail_us/anquanguanli_en.png")}`}
                                    loading="lazy"
                                />
                            </Grid>
                            <Grid item xs={7}>
                                <img
                                    src={`${getImageUrl("/about_us/detail_us/anquanguanli_zh.png")}`}
                                    loading="lazy"
                                />
                            </Grid>
                        </Grid>
                </Paper>
            </Box>
        </Box>
    )
}

function ContentDetail(){
    return <DrawBox/>
}
export default ContentDetail;