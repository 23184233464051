import axios from "axios";
let url = "";

switch (process.env.NODE_ENV) {
  // 生产环境，部署到服务器上的环境
  case "production":
    axios.defaults.baseURL = "http://shop.tongtanggift.com/mall-data/";
    url = "http://shop.tongtanggift.com/login";
    break;
  //设置测式环境的接口地址
  case "text":
    axios.defaults.baseURL = "http://60.205.213.211:5000";
    url = "http://60.205.213.211:5000/login";
    break;
  //开发环境接口地址
  default:
    axios.defaults.baseURL = "http://shop.tongtanggift.com/mall-data/";
    url = "http://localhost:3000/login";
    break;
}
const Service = axios.create({
  timeout: 3000, //延迟时间
  withCredentials: true, //是否是跨域请求
});

//请求拦截
Service.interceptors.request.use(
  (config) => {
    let headers = {
      "content-Type": "application/json",
    };
    config.headers = { ...headers, ...config.headers };
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

//响应拦截
Service.interceptors.response.use(
  (response) => {
    if (response.data.code != 403) {
      return Promise.resolve(response.data);
    } else if (response.data.code == 403) {
      window.location.reload();
    }
  },
  (error) => {
    return Promise.reject(error.response);
  }
);

export default Service;
