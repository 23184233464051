import React, { useState } from "react";
import "./index.scss";

import CloseIcon from "../../../assets/icon/close-icon2.png";
import CloseHoverIcon from "../../../assets/icon/close-hover-icon.png";
import { ossUrl } from "../../../ossUrl";
const urlOss = "https://images.tongtanggift.com/website/cases/self_case/";

const WholeBoxs = () => {
  const [IsBack, setIsBack] = useState(false);
  const [IsHoverClose, setIsHoverClose] = useState(false);
  const [ImgUrl, setImgUrl] = useState();
  const [IsHoverImg, setIsHoverImg] = useState(null);
  return (
    <div className="wholeBoxs">
      <div className="wholeBoxs-content">
        <div className="wholeBoxs-content-header">
          <div className="header-icon"></div>
        </div>
        <div className="wholeBoxs-content-list">
          <div className="list-left">
            <div
              className="list-left-img1"
              onClick={() => {
                setImgUrl(`${urlOss}left-back1.png${ossUrl()}`);
                setIsBack(true);
                document.documentElement.style.overflow = "hidden";
              }}
              onMouseEnter={() => {
                setIsHoverImg("left1");
              }}
              onMouseLeave={() => {
                setIsHoverImg(null);
              }}
            >
              <img
                src={`${urlOss}left-img1.png${ossUrl()}`}
                alt=""
                id="imgUrl"
              />
              {IsHoverImg == "left1" && (
                <div className="item-img">
                  <div className="item-img-back"></div>
                  <div className="item-img-content">
                    <div className="item-title">巨量2022年中秋礼盒</div>
                  </div>
                </div>
              )}
            </div>
            <div
              className="list-left-img2"
              onClick={() => {
                setImgUrl(`${urlOss}left-back2.png${ossUrl()}`);
                setIsBack(true);
                document.documentElement.style.overflow = "hidden";
              }}
              onMouseEnter={() => {
                setIsHoverImg("left2");
              }}
              onMouseLeave={() => {
                setIsHoverImg(null);
              }}
            >
              <img
                src={`${urlOss}left-img2.png${ossUrl()}`}
                alt=""
                id="imgUrl"
              />
              {IsHoverImg == "left2" && (
                <div className="item-img">
                  <div className="item-img-back"></div>
                  <div className="item-img-content">
                    <div className="item-title">得物2022新年礼盒</div>
                  </div>
                </div>
              )}
            </div>
            <div
              className="list-left-img3"
              onClick={() => {
                setImgUrl(`${urlOss}left-back3.png${ossUrl()}`);
                setIsBack(true);
                document.documentElement.style.overflow = "hidden";
              }}
              onMouseEnter={() => {
                setIsHoverImg("left3");
              }}
              onMouseLeave={() => {
                setIsHoverImg(null);
              }}
            >
              <img
                src={`${urlOss}left-img3.png${ossUrl()}`}
                alt=""
                id="imgUrl"
              />
              {IsHoverImg == "left3" && (
                <div className="item-img">
                  <div className="item-img-back"></div>
                  <div className="item-img-content">
                    <div className="item-title">快手磁力2021端午礼盒</div>
                  </div>
                </div>
              )}
            </div>
            <div
              className="list-left-img4"
              onClick={() => {
                setImgUrl(`${urlOss}left-back4.png${ossUrl()}`);
                setIsBack(true);
              }}
              onMouseEnter={() => {
                setIsHoverImg("left4");
              }}
              onMouseLeave={() => {
                setIsHoverImg(null);
              }}
            >
              <img
                src={`${urlOss}left-img4.png${ossUrl()}`}
                alt=""
                id="imgUrl"
              />
              {IsHoverImg == "left4" && (
                <div className="item-img">
                  <div className="item-img-back"></div>
                  <div className="item-img-content">
                    <div className="item-title">PICO 2022中秋礼盒</div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="list-center">
            <div
              className="list-center-img1"
              onClick={() => {
                setImgUrl(`${urlOss}center-back1.png${ossUrl()}`);
                setIsBack(true);
                document.documentElement.style.overflow = "hidden";
              }}
              onMouseEnter={() => {
                setIsHoverImg("center1");
              }}
              onMouseLeave={() => {
                setIsHoverImg(null);
              }}
            >
              <img
                src={`${urlOss}center-img1.png${ossUrl()}`}
                alt=""
                id="imgUrl"
              />
              {IsHoverImg == "center1" && (
                <div className="item-img">
                  <div className="item-img-back"></div>
                  <div className="item-img-content">
                    <div className="item-title">快手2022年中秋礼盒</div>
                  </div>
                </div>
              )}
            </div>
            <div
              className="list-center-img2"
              onClick={() => {
                setImgUrl(`${urlOss}center-back2.png${ossUrl()}`);
                setIsBack(true);
                document.documentElement.style.overflow = "hidden";
              }}
              onMouseEnter={() => {
                setIsHoverImg("center2");
              }}
              onMouseLeave={() => {
                setIsHoverImg(null);
              }}
            >
              <img
                src={`${urlOss}center-img2.png${ossUrl()}`}
                alt=""
                id="imgUrl"
              />
              {IsHoverImg == "center2" && (
                <div className="item-img">
                  <div className="item-img-back"></div>
                  <div className="item-img-content">
                    <div className="item-title">曹操出行2023新年礼盒</div>
                  </div>
                </div>
              )}
            </div>
            <div
              className="list-center-img3"
              onClick={() => {
                setImgUrl(`${urlOss}center-back3.png${ossUrl()}`);
                setIsBack(true);
                document.documentElement.style.overflow = "hidden";
              }}
              onMouseEnter={() => {
                setIsHoverImg("center3");
              }}
              onMouseLeave={() => {
                setIsHoverImg(null);
              }}
            >
              <img
                src={`${urlOss}center-img3.png${ossUrl()}`}
                alt=""
                id="imgUrl"
              />
              {IsHoverImg == "center3" && (
                <div className="item-img">
                  <div className="item-img-back"></div>
                  <div className="item-img-content">
                    <div className="item-title">好未来2023新年礼盒</div>
                  </div>
                </div>
              )}
            </div>
            <div
              className="list-center-img4"
              onClick={() => {
                setImgUrl(`${urlOss}center-back4.png${ossUrl()}`);
                setIsBack(true);
              }}
              onMouseEnter={() => {
                setIsHoverImg("center4");
              }}
              onMouseLeave={() => {
                setIsHoverImg(null);
              }}
            >
              <img
                src={`${urlOss}center-img4.png${ossUrl()}`}
                alt=""
                id="imgUrl"
              />
              {IsHoverImg == "center4" && (
                <div className="item-img">
                  <div className="item-img-back"></div>
                  <div className="item-img-content">
                    <div className="item-title">红布林2023新年礼盒</div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="list-right">
            <div
              className="list-right-img1"
              onClick={() => {
                setImgUrl(`${urlOss}right-back1.png${ossUrl()}`);
                setIsBack(true);
                document.documentElement.style.overflow = "hidden";
              }}
              onMouseEnter={() => {
                setIsHoverImg("right1");
              }}
              onMouseLeave={() => {
                setIsHoverImg(null);
              }}
            >
              <img
                src={`${urlOss}right-img1.png${ossUrl()}`}
                alt=""
                id="imgUrl"
              />
              {IsHoverImg == "right1" && (
                <div className="item-img">
                  <div className="item-img-back"></div>
                  <div className="item-img-content">
                    <div className="item-title">西瓜视频2021年新年礼盒</div>
                  </div>
                </div>
              )}
            </div>
            <div
              className="list-right-img2"
              onClick={() => {
                setImgUrl(`${urlOss}right-back2.png${ossUrl()}`);
                setIsBack(true);
                document.documentElement.style.overflow = "hidden";
              }}
              onMouseEnter={() => {
                setIsHoverImg("right2");
              }}
              onMouseLeave={() => {
                setIsHoverImg(null);
              }}
            >
              <img
                src={`${urlOss}right-img2.png${ossUrl()}`}
                alt=""
                id="imgUrl"
              />
              {IsHoverImg == "right2" && (
                <div className="item-img">
                  <div className="item-img-back"></div>
                  <div className="item-img-content">
                    <div className="item-title">巨量2022年新年礼盒</div>
                  </div>
                </div>
              )}
            </div>
            <div
              className="list-right-img3"
              onClick={() => {
                setImgUrl(`${urlOss}right-back3.png${ossUrl()}`);
                setIsBack(true);
                document.documentElement.style.overflow = "hidden";
              }}
              onMouseEnter={() => {
                setIsHoverImg("right3");
              }}
              onMouseLeave={() => {
                setIsHoverImg(null);
              }}
            >
              <img
                src={`${urlOss}right-img3.png${ossUrl()}`}
                alt=""
                id="imgUrl"
              />
              {IsHoverImg == "right3" && (
                <div className="item-img">
                  <div className="item-img-back"></div>
                  <div className="item-img-content">
                    <div className="item-title">虎扑2023新年礼盒</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {IsBack && (
        <div className="whole">
          <div
            className="whole-back"
            onClick={() => {
              setIsBack(false);
              setIsHoverClose(false);
            }}
          ></div>
          <div className="whole-back-content">
            <div
              className="back-icon"
              onMouseEnter={() => {
                setIsHoverClose(true);
              }}
              onMouseLeave={() => {
                setIsHoverClose(false);
              }}
              onClick={() => {
                setIsBack(false);
                setIsHoverClose(false);
              }}
            >
              <img src={IsHoverClose ? CloseHoverIcon : CloseIcon} alt="" />
            </div>
            <div className="back-img">
              <img src={ImgUrl} alt="" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default WholeBoxs;
