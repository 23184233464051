import { Box } from "@mui/material";
import Footer from "../../components/Footer";
import Banner from "./Banner";
import Benefits from "./Benefits";
import Office from "./Office";
import Recruiting from "./Recruiting";
import { useState, useEffect } from "react";
function Join() {
  const [innerWidth, setInnerWidth] = useState(0);
  useEffect(() => {
    setInnerWidth(window.innerWidth);
    function watchWindowSize() {
      setInnerWidth(window.innerWidth);
    }
    window.addEventListener("resize", watchWindowSize);
    return () => window.addEventListener("resize", watchWindowSize);
  }, [window.innerWidth]);
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        width: "100%",
        flex: "1 1 auto",
        zoom: innerWidth >= 1920 ? 1 : 0.70,
      }}
    >
      <Banner />
      <Office />
      <Benefits />
      <Recruiting />
      <Footer />
    </Box>
  );
}

export default Join;
