/*
 * @Author: yc 422874523@qq.com
 * @Date: 2023-02-11 17:38:34
 * @LastEditors: yc 422874523@qq.com
 * @LastEditTime: 2023-02-12 18:36:28
 * @FilePath: \site\tongtang-website\src\App.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import {Route, Router, Switch} from "react-router-dom";
import './App.scss';
import { createBrowserHistory } from 'history';
import {Provider} from "react-redux";

import Header from "./components/Header";
import Head from "./components/Head";
import Home from "./pages/Home";
import Cases from "./pages/Cases";
import AboutUs from "./pages/AboutUs";
import Join from "./pages/Join";
import FourNot from "./pages/FourNot";
import { ABOUT_US, CASES, HOME, JOIN,FOURNOT,DETAIL_US } from "./constants/urls";
import { createTheme, ThemeProvider } from "@mui/material";
import ScrollToTop from "./ScrollToTop";
import ScrollToTopButton from "./components/ScrollToTopButton";
import { useState,useEffect } from "react";
import GlobalImageLoader from "./components/GlobalImageLoader";
import store from "./redux/store";
import DetailUs from "./pages/DetailUs";

const history = createBrowserHistory();

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF4713",
      light: "#FF7005",
    },
  },
});
function App() {
  const [loadedAllImages, setLoadedAllImages] = useState(false);
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme} >
        {/* {!loadedAllImages && <GlobalImageLoader onLoadedAllImages={() => setLoadedAllImages(true)} />} */}
        <Router history={history}>
          {window.location.pathname!='/fournot'?<Head />:''}
          {window.location.pathname!='/fournot'?<ScrollToTop />:''}
          {window.location.pathname!='/fournot'?<Switch>
            <Route path={HOME} component={Home} exact />
            <Route path={CASES} component={Cases} exact />
            <Route path={ABOUT_US} component={AboutUs} exact />
            <Route path={JOIN} component={Join} exact />
            <Route path={DETAIL_US} component={DetailUs} exact />
          </Switch>:<Switch><Route path={FOURNOT} component={FourNot} exact /></Switch>}
          {window.location.pathname!='/fournot'?<ScrollToTopButton />:''}
        </Router>
        {/* {!!loadedAllImages && <Router history={history}>
          {window.location.pathname!='/fournot'?<Head />:''}
          {window.location.pathname!='/fournot'?<ScrollToTop />:''}
          {window.location.pathname!='/fournot'?<Switch>
            <Route path={HOME} component={Home} exact />
            <Route path={CASES} component={Cases} exact />
            <Route path={ABOUT_US} component={AboutUs} exact />
            <Route path={JOIN} component={Join} exact />
            <Route path={DETAIL_US} component={DetailUs} exact />
          </Switch>:<Switch><Route path={FOURNOT} component={FourNot} exact /></Switch>}
          {window.location.pathname!='/fournot'?<ScrollToTopButton />:''}
        </Router>} */}
      </ThemeProvider>
    </Provider>
  );
}

export default App;
