import { Box } from "@mui/material";
import { getImageUrl } from "../../helpers/urlHelper";
const FourNot = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{
        display:"flex",
        flexDirection:"column"
      }}>
        <Box
          sx={{
            width: "340px",
            height: "390px",
            backgroundImage: `url(${getImageUrl(
              "/cases/contact_us/default.png"
            )})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        ></Box>
        <Box
          sx={{
            width: "340px",
            fontSize: "16px",
            marginTop: "20px",
            textAlign:"center"
          }}
        >
          在线商城暂未上线，敬请期待～
        </Box>
      </Box>
    </Box>
  );
};

export default FourNot;
