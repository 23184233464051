import React, { useState, useEffect } from "react";
import "./index.scss";
import {
  queryGoodsSimpleInfoList,
  queryHomeCategoryList,
} from "../../../../api/index.js";
import PopularIcon from "../../../../assets/icon/popular-icon.png";
import HOTIcon from "../../../../assets/icon/HOT.png";
let topDic = [];
const Popular = () => {
  const [dataList, setDataList] = useState();
  const [GoodsIndex, setGoodsIndex] = useState(0);
  const [hoverIndex, setHoverIndex] = useState(null);
  const GetHomeCategoryList = async () => {
    const { data, code } = await queryHomeCategoryList();
    if (code == 200) {
      topDic = JSON.parse(JSON.stringify(data[1]?.homeCategoryVoList));
      for (var i = 0; i < topDic?.length; i++) {
        const simple = await GetGoodsSimpleInfoList(
          data[1]?.homeCategoryVoList?.[i]?.categoryId
        );
        topDic[i].simpleGoodsInfoVoList = simple.list;
      }
      let str = [...topDic];
      setDataList(str);
    }
  };
  const GetGoodsSimpleInfoList = async (categoryId) => {
    const { data, code } = await queryGoodsSimpleInfoList({
      categoryId: categoryId,
    });
    if (code == 200) {
      return data;
    }
  };
  useEffect(() => {
    GetHomeCategoryList();
  }, []);

  const changeIndex = (index) => {
    setGoodsIndex(index);
  };

  const goGoods = (item) => {
    window.open(
      `http://shop.tongtanggift.com/product/details?goodsId=${item.goodsId}`
    );
  };
  return (
    <div className="popular">
      <div className="popular-content">
        <div className="popular-header">
          <div className="popular-header-icon">
            <img src={PopularIcon} alt="" />
          </div>
        </div>
        <div className="popular-content-header">
          <div className="header-menu">
            {dataList &&
              dataList.map((item, index) => {
                return (
                  <div
                    className="header-menu-item"
                    key={index}
                    onClick={() => changeIndex(index)}
                  >
                    <div
                      className={
                        index == GoodsIndex
                          ? "item-title item-title-active"
                          : "item-title"
                      }
                    >
                      {item.name}
                    </div>
                    {index + 1 == dataList?.length ? (
                      ""
                    ) : (
                      <div className="item-line">/</div>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
        <div className="popular-content-footer">
          {dataList?.[GoodsIndex]?.simpleGoodsInfoVoList &&
            dataList?.[GoodsIndex]?.simpleGoodsInfoVoList.map((item, index) => {
              return (
                <div
                  className={
                    (index + 1) % 5 == 0
                      ? "footer-item footer-item-active"
                      : "footer-item"
                  }
                  key={index}
                  onClick={() => goGoods(item)}
                  onMouseEnter={() => {
                    setHoverIndex(index);
                  }}
                  onMouseLeave={() => {
                    setHoverIndex(null);
                  }}
                >
                  <img src={item.imageUrl} alt="" id="imageUrl" />
                  {hoverIndex == index && (
                    <div className="footer-item-back">
                      <div className="back-title">{item.name}</div>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </div>
      <div className="popular-back">
            <img src={HOTIcon} alt="" />
      </div>
    </div>
  );
};
export default Popular;
