/*
 * @Author: yc 422874523@qq.com
 * @Date: 2023-02-18 19:13:33
 * @LastEditors: yc 422874523@qq.com
 * @LastEditTime: 2023-02-19 22:48:49
 * @FilePath: \site\tongtang-website\src\pages\Home\components\StrengthBased\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useState } from "react";
import "./index.scss";
import BlackStar from "../../../../assets/icon/black-star.png";
import StrengthBasedIcon from "../../../../assets/icon/Strength-based.png";
import SliceRight from "../../../../assets/icon/slice-right.png";
import CloseIcon from "../../../../assets/icon/close-icon.png";
import CloseHoverIcon from "../../../../assets/icon/close-hover-icon.png";
import PowerIcon from "../../../../assets/icon/Power.png";
import { ossUrl } from "../../../../ossUrl";
import { Power } from "@mui/icons-material";
const urlOss = "https://images.tongtanggift.com/website/home/new_img/";
const StrengthBased = () => {
  const [liHover, setLiHover] = useState(null);
  const [IsHover, setIsHover] = useState(false);
  const [IsHoverClose, setIsHoverClose] = useState(false);
  const [UrlData, setUrlData] = useState();
  const [Size, setSize] = useState(0);
  const dataList = [
    {
      id: 1,
      imgUrl: `${urlOss}Strength-based1.png${ossUrl()}`,
      name: "品类齐全",
      bigUrl: `${urlOss}strend-big1.png${ossUrl()}`,
      littleUrl: `${urlOss}strend-little1.png${ossUrl()}`,
      listUrl: [
        `https://images.tongtanggift.com/website/home/advantages/detail_view/detail1-1@2x.png${ossUrl()}`,
      ],
      describe:"产品线覆盖常用定制品的全品类，包括印刷品、工艺品、服饰箱包、数码3C、日用家居等10个大类、35个中类、187个小类。"
    },
    {
      id: 2,
      imgUrl: `${urlOss}Strength-based2.png${ossUrl()}`,
      name: "设计赋能",
      bigUrl: `${urlOss}strend-big2.png${ossUrl()}`,
      littleUrl: `${urlOss}strend-little2.png${ossUrl()}`,
      listUrl: [
        `https://images.tongtanggift.com/website/home/advantages/detail_view/detail2-1@2x.png${ossUrl()}`,
        `https://images.tongtanggift.com/website/home/advantages/detail_view/detail2-2@2x.png${ossUrl()}`,
      ],
      describe:"完善的创意设计团队，包括创意组、平面组、手绘组、结构组；海量的模块化设计素材，可快速响应方案，设计师团队多次荣获国内外奖项，具备多样化的设计风格和落地经验。"
    },
    {
      id: 3,
      imgUrl: `${urlOss}Strength-based3.png${ossUrl()}`,
      name: "完美交付",
      bigUrl: `${urlOss}strend-big3.png${ossUrl()}`,
      littleUrl: `${urlOss}strend-little3.png${ossUrl()}`,
      listUrl: [
        `https://images.tongtanggift.com/website/home/advantages/detail_view/detail3-1@2x.png${ossUrl()}`,
        `https://images.tongtanggift.com/website/home/advantages/detail_view/detail3-2@2x.png${ossUrl()}`,
        `https://images.tongtanggift.com/website/home/advantages/detail_view/detail3-3@2x.png${ossUrl()}`,
        `https://images.tongtanggift.com/website/home/advantages/detail_view/detail3-4@2x.png${ossUrl()}`,
      ],
      describe:"5R供应链管理原则，适时、适质、适量、适价、适地；110家战略合作工厂，667家储备工厂，覆盖华东、华南、华北、华中四大片区，充分满足定制品交付需求。"
    },
    {
      id: 4,
      imgUrl: `${urlOss}Strength-based4.png${ossUrl()}`,
      name: "仓储售后",
      bigUrl: `${urlOss}strend-big4.png${ossUrl()}`,
      littleUrl: `${urlOss}strend-little4.png${ossUrl()}`,
      listUrl: [
        `https://images.tongtanggift.com/website/home/advantages/detail_view/detail4-1@2x.png${ossUrl()}`,
      ],
      describe:"北京、深圳、西安、东莞、武汉五大仓储共计22000平方米，高度数字化管理，可满足日发货1万+等大型定制品组装、发货需求。"
    },
    {
      id: 5,
      imgUrl: `${urlOss}Strength-based5.png${ossUrl()}`,
      name: "数字化",
      bigUrl: `${urlOss}strend-big5.png${ossUrl()}`,
      littleUrl: `${urlOss}strend-little5.png${ossUrl()}`,
      listUrl: [
        `https://images.tongtanggift.com/website/home/advantages/detail_view/detail5-1@2x.png${ossUrl()}`,
        `https://images.tongtanggift.com/website/home/advantages/detail_view/detail5-2@2x.png${ossUrl()}`,
      ],
      describe:"自建产研团队，自营定制礼品垂类电商平台，标准化定制品类库，服务中小型B端客户的礼品定制需求，同时也为KA客户提供礼品在线领取、物流订单跟踪、营销H5页面等增值IT服务。"
    },
  ];
  return (
    <div className="strengthBased">
      <div className="strengthBased-content">
        <div className="strengthBased-content-header">
          <div className="header-right-icon">
            <img src={StrengthBasedIcon} alt="" />
          </div>
        </div>
        <div className="strengthBased-list">
          {dataList.map((item, index) => {
            return (
              <div
                className="list-item"
                key={index}
                onMouseEnter={()=>{setLiHover(item.id)}}
                onMouseLeave={()=>{setLiHover(null)}}
                onClick={() => {
                  setUrlData(item.listUrl);
                  setIsHover(true);
                }}
              >
                <img src={item.imgUrl} alt="" id="ImgUrl" />
                <div className="list-item-back">
                  <div className="list-item-back-mack" style={{opacity:liHover==item.id?0.6:0.4}}></div>
                  <div className={liHover==item.id?"list-item-back-title list-item-back-title-active":"list-item-back-title"} >
                    {liHover==item.id?item.describe:item.name}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {/* <div className="strengthBased-content-list">
          <ul className="list-hover">
            {dataList.map((item, index) => {
              return (
                <li
                  className={
                    item.id == liHover
                      ? "list-hover-item list-hover-item-active"
                      : "list-hover-item"
                  }
                  style={{
                    transition: "all 0.4s ease-in-out",
                  }}
                  key={index}
                  onMouseEnter={() => {
                    setLiHover(item.id);
                  }}
                  onClick={() => {
                    setUrlData(item.listUrl);
                    setIsHover(true);
                  }}
                >
                  {item.id == liHover ? (
                    <img src={item.imgUrl} alt="" id="ImgUrl" />
                  ) : (
                    ""
                  )}
                  {item.id == liHover ? (
                    <div
                      className="item-title"
                      style={{ opacity: item.id == liHover ? 1 : 0 }}
                    >
                      <div className="item-title-No">
                        <img src={item.bigUrl} alt="" />
                      </div>
                      <div className="item-title-name">{item.name}</div>
                    </div>
                  ) : (
                    ""
                  )}
                  {item.id == liHover ? (
                    ""
                  ) : (
                    <div className="item-title-hover">
                      <div
                        className={
                          index + 1 == 5
                            ? "item-title-hover-No item-title-hover-No-active"
                            : "item-title-hover-No"
                        }
                      >
                        <img src={item.littleUrl} alt="" />
                      </div>
                      <div className="item-title-hover-name">{item.name}</div>
                      <div className="item-title-hover-icon">
                        <img src={SliceRight} alt="" />
                      </div>
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        </div> */}
      </div>
      <div className="strength-power">
        <img src={PowerIcon} alt="" />
      </div>
      {IsHover && (
        <div className="strend">
          <div
            className="strend-back"
            onClick={() => {
              setIsHover(false);
              setIsHoverClose(false);
              setSize(0);
            }}
          ></div>
          <div className="strend-content">
            <div
              className="strend-content-icon"
              onClick={() => {
                setIsHover(false);
                setIsHoverClose(false);
                setSize(0);
              }}
              onMouseEnter={() => {
                setIsHoverClose(true);
              }}
              onMouseLeave={() => {
                setIsHoverClose(false);
              }}
            >
              <img src={IsHoverClose ? CloseHoverIcon : CloseIcon} alt="" />
            </div>
            <div className="strend-content-list">
              {Size > 0 ? (
                <div
                  className="button-prev"
                  onClick={() => {
                    if (Size > 0) {
                      setSize((Size) => Size - 1);
                    }
                  }}
                ></div>
              ) : (
                ""
              )}
              {UrlData &&
                UrlData.map((item, index) => {
                  return (
                    <div
                      className={
                        index == Size
                          ? "list-item"
                          : Size > index
                          ? "list-item-active"
                          : "list-item-next"
                      }
                      key={index}
                    >
                      <img src={item} alt="" />
                    </div>
                  );
                })}
              {UrlData?.length - 1 > Size ? (
                <div
                  className="button-next"
                  onClick={() => {
                    setSize((Size) => Size + 1);
                  }}
                ></div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default StrengthBased;
