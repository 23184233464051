/*
 * @Author: yc 422874523@qq.com
 * @Date: 2023-02-12 14:32:30
 * @LastEditors: yc 422874523@qq.com
 * @LastEditTime: 2023-02-19 14:20:04
 * @FilePath: \site\tongtang-website\src\pages\Home\components\Business\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useState, useEffect } from "react";
import "./index.scss";
import BusinessIcon from "../../../../assets/icon/business-icon.png";
import BusinesBack from "../../../../assets/icon/Busines.png";
import { ossUrl } from "../../../../ossUrl";
const urlOss = "https://images.tongtanggift.com/website/home/new_img/";
const Business = () => {
  const [imgIndex, setImgIndex] = useState(0);
  const [dataList, setDataList] = useState([]);
  const [IsGoods, setIsGoods] = useState(1);
  const topList = [
    {
      imageUrl: `${urlOss}Product-line1.png${ossUrl()}`,
      name: "节庆礼盒全案定制",
    },
    {
      name: "日常礼品&商务礼赠高效定制",
      imageUrl: `${urlOss}Product-line2.png${ossUrl()}`,
    },
    {
      name: "企业IP延展&周边产品研发",
      imageUrl: `${urlOss}Product-line3.png${ossUrl()}`,
    },
    {
      name: "创意联名&非遗文化产品研发",
      imageUrl: `${urlOss}Product-line4.png${ossUrl()}`,
    },
  ];
  const topList2 = [
    {
      imageUrl: `${urlOss}Service-line1.png${ossUrl()}`,
      name: "定制品生产交付",
    },
    {
      name: "定制商城系统搭建",
      imageUrl: `${urlOss}Service-line2.png${ossUrl()}`,
    },
    {
      name: "仓储管理一件代发",
      imageUrl: `${urlOss}Service-line3.png${ossUrl()}`,
    },
  ];
  useEffect(() => {
    setDataList(topList);
  }, []);
  return (
    <div className="business">
      <div className="business-top">
        <div className="business-top-icon">
          <img src={BusinessIcon} alt="" />
        </div>
      </div>
      <div className="business-content">
        <div className="menu-data">
          <div className="menu-data-left">
            <div className="menu-title">
              <div
                className={
                  IsGoods == 1
                    ? "menu-title-item menu-title-item-active"
                    : "menu-title-item"
                }
                onClick={() => {
                  setIsGoods(1);
                  setImgIndex(0);
                  setDataList(topList);
                }}
              >
                产品线
              </div>
              <div
                className={
                  IsGoods == 2
                    ? "menu-title-item menu-title-item-active"
                    : "menu-title-item"
                }
                onClick={() => {
                  setIsGoods(2);
                  setImgIndex(0);
                  setDataList(topList2);
                }}
              >
                服务线
              </div>
            </div>
            <div className="menu-list">
              {dataList &&
                dataList.map((item, index) => {
                  return (
                    <div
                      className={
                        index == imgIndex
                          ? "menu-list-item menu-list-item-active"
                          : "menu-list-item"
                      }
                      key={index}
                      onMouseEnter={() => {
                        setImgIndex(index);
                      }}
                    >
                      <div className="item-No">0{index + 1}</div>
                      <div className="item-name">{item.name}</div>
                    </div>
                  );
                })}
            </div>
          </div>

          <div className="menu-list-img">
            <img src={dataList[imgIndex]?.imageUrl} alt="" />
          </div>
        </div>
      </div>
      <div className="busines-back">
          <img src={BusinesBack} alt="" />
      </div>
    </div>
  );
};
export default Business;
