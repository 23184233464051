import React, { useState } from "react";
import "./index.scss";
import CultureLeft from "../../../../assets/icon/culture-left-icon.png";
import CultureCenter from "../../../../assets/icon/culture-center-icon.png";
import CultureRight from "../../../../assets/icon/culture-right-icon.png";
import Slogan from "../../../../assets/icon/Slogan.png";
import CompanyProfile from "../../../../assets/icon/Company-Profile.png";
import { ossUrl } from "../../../../ossUrl";
const urlOss = "https://images.tongtanggift.com/website/home/new_img/";
const Culture = () => {
  const [Ishover, setIsHover] = useState(null);
  return (
    <div className="culture">
      {/* <div className="culture-content">
        <div className="culture-content-top">
          <div
            className="culture-content-top-item1"
            onMouseEnter={() => {
              setIsHover(1);
            }}
            onMouseLeave={() => {
              setIsHover(null);
            }}
          >
            <img
              src={`${urlOss}culture-left.png${ossUrl()}`}
              alt=""
              id="Item1"
            />
            <div
              className="item-name"
              style={{
                color: Ishover == 1 ? "#fff" : "#2d2e33",
                transition: "all 0.4s ease-in-out",
              }}
            >
              年轻.
            </div>
            <div
              className="item-back"
              style={{
                opacity: Ishover == 1 ? 1 : 0,
                transition: "all 0.4s ease-in-out",
              }}
            ></div>

            <div
              className="item-title"
              style={{
                opacity: Ishover == 1 ? 1 : 0,
                transition: "all 0.4s ease-in-out",
              }}
            >
              铜棠有礼专注打造一支年轻的团队
              <br />
              平均年龄28岁
              <br />
              洞悉年轻化、潮流化、多元化的客户需求
            </div>

            <div
              className="item-icon"
              style={{
                opacity: Ishover == 1 ? 1 : 0,
                transition: "all 0.4s ease-in-out",
              }}
            >
              <img src={CultureLeft} alt="" />
            </div>
          </div>
          <div
            className="culture-content-top-item2"
            onMouseEnter={() => {
              setIsHover(2);
            }}
            onMouseLeave={() => {
              setIsHover(null);
            }}
          >
            <img
              src={`${urlOss}culture-center.png${ossUrl()}`}
              alt=""
              id="Item2"
            />
            <div className="item-name">专业.</div>
            <div
              className="item-back"
              style={{
                opacity: Ishover == 2 ? 1 : 0,
                transition: "all 0.4s ease-in-out",
              }}
            ></div>

            <div
              className="item-title"
              style={{
                opacity: Ishover == 2 ? 1 : 0,
                transition: "all 0.4s ease-in-out",
              }}
            >
              设计师荣获多项国内外奖项
              <br />
              经验丰富的项目执行团队
              <br />
              高标准的供应链管理能力
            </div>

            <div
              className="item-icon"
              style={{
                opacity: Ishover == 2 ? 1 : 0,
                transition: "all 0.4s ease-in-out",
              }}
            >
              <img src={CultureCenter} alt="" />
            </div>
          </div>
          <div
            className="culture-content-top-item3"
            onMouseEnter={() => {
              setIsHover(3);
            }}
            onMouseLeave={() => {
              setIsHover(null);
            }}
          >
            <img
              src={`${urlOss}culture-right.png${ossUrl()}`}
              alt=""
              id="Item3"
            />
            <div
              className="item-name"
              style={{
                color: Ishover == 3 ? "#fff" : "#2d2e33",
                transition: "all 0.4s ease-in-out",
              }}
            >
              高效.
            </div>
            <div
              className="item-back"
              style={{
                opacity: Ishover == 3 ? 1 : 0,
                transition: "all 0.4s ease-in-out",
              }}
            ></div>

            <div
              className="item-title"
              style={{
                opacity: Ishover == 3 ? 1 : 0,
                transition: "all 0.4s ease-in-out",
              }}
            >
              选品、设计、打样、生产、售后
              <br />
              一站式全案礼品定制和设计落地能力
              <br />
              快速响应，高效落地
            </div>

            <div
              className="item-icon"
              style={{
                opacity: Ishover == 3 ? 1 : 0,
                transition: "all 0.4s ease-in-out",
              }}
            >
              <img src={CultureRight} alt="" />
            </div>
          </div>
        </div>
        <div className="culture-content-footer">
          <div className="footer-h1">为什么选择我们？</div>
          <div className="footer-h2">
            字节、快手、得物等头部互联网企业的优秀礼品供应商，丰富的企业礼品、周边、IP全案定制项目落地经验，覆盖多种客户类型
            <br />
            的设计风格，全品类的优质供应链工厂，为您提供最专业、高效、省心的企业定制服务。
          </div>
        </div>
      </div> */}
      <div className="culture-top">
        <div className="culture-top-icon">
          <img src={CompanyProfile} alt="" />
        </div>
      </div>
      <div className="culture-list">
        <div className="culture-list-item">
          <div className="culture-list-item-title">3<span>家</span></div>
          <div className="culture-list-item-name">北京、上海、深圳</div>
          <div className="culture-list-item-describe">2018年创立，总部位于北京，3个城市设立3个分公司，服务于全国各地的客户</div>
        </div>
        <div className="culture-list-item">
          <div className="culture-list-item-title">50+</div>
          <div className="culture-list-item-name">各领域行业精英</div>
          <div className="culture-list-item-describe">其中包括15位多次荣获国际设计大奖的设计师和具备丰富项目管理落地经验的供应链伙伴，为客户提供最专业的服务</div>
        </div>
        <div className="culture-list-item">
          <div className="culture-list-item-title">100+</div>
          <div className="culture-list-item-name">各行业头部客户</div>
          <div className="culture-list-item-describe">包括腾讯、华为、小米、字节、头条、得物、光大等各行业头部客户，与他们均保持长期稳定的合作关系</div>
        </div>
        <div className="culture-list-item">
          <div className="culture-list-item-title">600+</div>
          <div className="culture-list-item-name">优质供应商</div>
          <div className="culture-list-item-describe">覆盖定制品全品类、分布全国各区域的优质供应商，每季度会对供应商进行评价并更新，严格保证产品质量</div>
        </div>
      </div>
      <div className="culture-back">
        <img src={Slogan} alt="" />
      </div>
    </div>
  );
};

export default Culture;
