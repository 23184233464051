import React, { useState, useEffect } from "react";
import "./index.scss";
import { Navigation, Pagination, A11y,Autoplay  } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import { ossUrl } from "../../../../ossUrl";
const urlOss = "https://images.tongtanggift.com/website/home/new_img/";
const Banner = () => {
  return (
    <div className="banner">
      <div className="banner-content">
        <Swiper
          // install Swiper modules
          modules={[Navigation, Pagination, A11y,Autoplay]}
          spaceBetween={0}
          slidesPerView={"auto"}
          loop={true} //循环轮播
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          navigation
          mousewheel={false}
          pagination={{
            clickable: true,
            renderBullet: function (index, className) {
              return (
                '<span class="' + className + '">' + 0 + (index + 1) + "</span>"
              );
            },
          }}
          //onSwiper={(swiper) => console.log(swiper)}
          //onSlideChange={() => console.log("slide change")}
          className={"swiper-no-swiping"}
        >
          <SwiperSlide key={1}>
            <img src={`${urlOss}banner-01.png${ossUrl()}`} alt="" />
          </SwiperSlide>
          <SwiperSlide key={2}>
            <img src={`${urlOss}banner-02.png${ossUrl()}`} alt="" />
          </SwiperSlide>
          <SwiperSlide key={3}>
            <img src={`${urlOss}banner-03.png${ossUrl()}`} alt="" />
          </SwiperSlide>
          {/* <SwiperSlide key={4}>
            <img src={`${urlOss}banner-04.png${ossUrl()}`} alt="" />
          </SwiperSlide> */}
        </Swiper>
        {/* <div className="banner-icon"></div>
        <div className="banner-tips">
          <div className="banner-tips-left"></div>
          <div className="banner-tips-center"></div>
          <div className="banner-tips-right"></div>
        </div> */}
      </div>
    </div>
  );
};
export default Banner;
