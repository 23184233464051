import { Box } from "@mui/material";
import Footer from "../../components/Footer";
import Banner from "./Banner";
import CompanyIntro from "./CompanyIntro";
import Location from "./Location";
import Qualifications from "./Qualifications";
import { useState, useEffect } from "react";
function AboutUs() {
    const [innerWidth, setInnerWidth] = useState(0);
    useEffect(() => {
      setInnerWidth(window.innerWidth);
      function watchWindowSize() {
        setInnerWidth(window.innerWidth);
      }
      window.addEventListener("resize", watchWindowSize)
      return () => window.addEventListener("resize", watchWindowSize);
    }, [window.innerWidth]);
    return (
        <Box display="flex" flexDirection="column" sx={{
            width: "100%",
            flex: "1 1 auto",
            alignItems: "center",
            zoom: innerWidth >= 1920 ? 1 : 0.70,
        }}>
            <Banner />
            <CompanyIntro />
            <Qualifications />
            <Location />
            <Footer />
        </Box>
    )
}

export default AboutUs;