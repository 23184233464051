import { Box } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { getImageUrl } from "../../helpers/urlHelper";
function ScrollToTopButton() {
  const [show, setShow] = useState(false);
  const [innerWidth, setInnerWidth] = useState(0);
  useEffect(() => {
    setInnerWidth(window.innerWidth);
    function watchWindowSize() {
      setInnerWidth(window.innerWidth);
    }
    window.addEventListener("resize", watchWindowSize);
    return () => window.addEventListener("resize", watchWindowSize);
  }, [window.innerWidth]);
  window.addEventListener("scroll", (e) => {
    if (window.scrollY > window.innerHeight && !show) {
      setShow(true);
    } else if (window.scrollY <= window.innerHeight && show) {
      setShow(false);
    }
  });

  const onClick = useCallback(() => {
    document.documentElement.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <Box
      onClick={onClick}
      sx={{
        position: "fixed",
        right: "16px",
        opacity: !show ? 0 : 1,
        bottom: !show ? "-60px" : "32px",
        transition: "all 0.25s ease-in-out",
        width: "60px",
        height: "60px",
        backgroundImage: `url(${getImageUrl("/back_to_top@2x.png")})`,
        backgroundSize: "contain",
        cursor: "pointer",
        zIndex: 10000,
        zoom: innerWidth > 1920 ? 1 : 0.75,
      }}
    />
  );
}

export default ScrollToTopButton;
