import R from "../resources/Namespace"
let urlOss = "?x-oss-process=image/format,webp";
var avif = new Image();
avif.src =
  "data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A=";
avif.onload = function () {
  urlOss = "?x-oss-process=image/format,avif";
};
avif.onerror = function () {
  urlOss = "?x-oss-process=image/format,webp";
};
export const getImageUrl = (path: string) => {
    // For testing purpose - testing if CDN works
    // return process.env.NODE_ENV === "development" ? `/${path}` : `${R.endpoints.OSS_ACCELERATE_ROOT}/${path}`;

    return `${R.endpoints.OSS_ACCELERATE_ROOT}${path}${urlOss}`;
}