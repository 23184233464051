import React from "react";
import "./index.scss";
import CustomerIcon from "../../../../assets/icon/Customer-icon.png";
import Coop from "../../../../assets/icon/COOP.png";
import { ossUrl } from "../../../../ossUrl";
const urlOss = "https://images.tongtanggift.com/website/home/new_img/";
const CustomerWall=()=>{
    return (
        <div className="customer-wall">
            <div className="customer-wall-icon">
                <img src={CustomerIcon} alt="" />
            </div>
            <div className="customer-wall-back">
                <img src={`${urlOss}Customer-wall.png${ossUrl()}`} alt="" />
            </div>
            <div className="customer-wall-coop">
                <img src={Coop} alt="" />
            </div>
        </div>
    )
}
export default CustomerWall;