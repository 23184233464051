const strings = {
  header: {
    tabs: {
      label_home: "首页",
      label_cases: "案例",
      label_us: "我们",
      label_join: "加入",
    },
    button: {
      mall: "一点礼至",
    },
  },
  common: {
    more_plus: "More+",
  },
  footer: {
    contact_us: "联系我们",
    email: "邮箱：market@hxtongtang.com",
    phone: "电话：400-888-3905",
    address: "地址：北京市朝阳区望京中环南路甲2号19层A1905",
    official_qr_code: "官方微信二维码",
    claim1: "©2019-2024",
    claim2: "版权所有 北京春湘科技有限公司",
    claim3: "京ICP备19049540号-1",
    claim5: "部分设计元素由客户提供，本站服务案例展示不代表版权归属情况",
  },
  business_scope: {
    product_line: "产品线",
    service_line: "服务线",
    contents: {
      holiday_gift: {
        icon: "/home/business_scope/holiday_gift@2x.png",
        mainText: "节日福利礼品",
        secondaryText: "全案定制",
      },
      daily_gift: {
        icon: "/home/business_scope/daily_gift@2x.png",
        mainText: "日常礼品 & 市场礼品",
        secondaryText: "高效定制",
      },
      ip_gift: {
        icon: "/home/business_scope/ip_gift@2x.png",
        mainText: "IP 产品",
        secondaryText: "研发落地",
      },
      culture_gift: {
        icon: "/home/business_scope/culture_gift@2x.png",
        mainText: "非遗文化创意产品",
        secondaryText: "全案定制",
      },
      supplychain_service: {
        icon: "/home/business_scope/supplychain_service@2x.png",
        mainText: "供应链交付",
      },
      it_service: {
        icon: "/home/business_scope/it_service@2x.png",
        mainText: "数字化 & 互联网管理",
      },
      warehouse_service: {
        icon: "/home/business_scope/warehouse_service@2x.png",
        mainText: "仓储管理 & 物流",
      },
    },
  },
  advantages: {
    advantage1: "品类齐全",
    advantage2: "设计赋能",
    advantage3: "完美交付",
    advantage4: "仓储售后",
    advantage5: "数字化",
    detail_view: {
      title1_1: "丰富的品类库",
      title2_1: "完善的创意设计团队",
      title2_2: "海量的创意素材库",
      title3_1: "5R 供应链管理原则",
      title3_2: "合作供应链工厂",
      title3_3: "供应链分布",
      title3_4: "供应链缩影",
      title4_1: "完善的仓储物流体系",
      title5_1: "在线礼品订购平台",
      title5_2: "WMS 仓储物流管理系统",
    },
  },
  cases: {
    section1: {
      content1: {
        title1: "/cases/section1/title1@2x.png",
        detail1: "- 提供节日福利、活动礼盒的全案定制",
        detail2: "- 包括选品推荐、视觉设计、打样生产、物流交付等全流程服务",
        detail3: "- 无忧定制专属礼盒",
      },
      content2: {
        title1: "/cases/section1/title2@2x.png",
        detail1: "- 提供臻选品质、工艺、材料的单品",
        detail2: "- 产品库种类齐全，价格区间覆盖广泛",
        detail3: "- 随心挑选优质单品",
      },
      content3: {
        title1: "/cases/section1/title3@2x.png",
        detail1: "- 提供IP产品研发、IP联名项目设计落地",
        detail2: "- 提供非遗文化创意产品、衍生品的开发定制",
        detail3: "- 用产品传承文化与内涵",
      },
      content4: {
        title1: "/cases/section1/title4@2x.png",
        detail1: "- 提供原创的设计提案，延伸品牌和视觉传达",
        detail2: "- 包括平面、3D、结构、手绘等多种设计能力",
        detail3: "- 让设计更富创造性",
      },
      nav1: {
        icon: "/cases/section1/icon1@2x.png",
        icon_hovered: "/cases/section1/icon1-hovered@2x.png",
        title: "全案礼盒",
        sub_title: "Whole Box",
      },
      nav2: {
        icon: "/cases/section1/icon2@2x.png",
        icon_hovered: "/cases/section1/icon2-hovered@2x.png",
        title: "臻选单品",
        sub_title: "Tongtang Reserve",
      },
      nav3: {
        icon: "/cases/section1/icon3@2x.png",
        icon_hovered: "/cases/section1/icon3-hovered@2x.png",
        title: "文化周边",
        sub_title: "Culture Derivative",
      },
      nav4: {
        icon: "/cases/section1/icon4@2x.png",
        icon_hovered: "/cases/section1/icon4-hovered@2x.png",
        title: "创意设计",
        sub_title: "Creative Design",
      },
    },
    whole_box: {
      box1: {
        title: "得物2022新年礼盒",
        items: [
          "创意手表",
          "定制笔记本",
          "定制口罩",
          "口罩袋",
          "虎年红包",
          "新春贴纸",
        ],
      },
      box2: {
        title: "西瓜视频2020年中秋礼盒",
        items: ["感谢信", "购物袋", "月饼", "音箱"],
      },
      box3: {
        title: "宇信2020年中秋礼盒",
        items: ["公仔-戴帽", "马克杯", "帆布袋", "包装盒", "月饼"],
      },
      box4: {
        title: "巨量2022年新年礼盒",
        items: ["茶具", "茶叶", "抛光灯", "钥匙扣", "春联", "新春红包"],
      },
      box5: {
        title: "快手磁力2021端午礼盒",
        items: ["粽子", "放映机", "公仔", "钥匙扣", "小罐茶"],
      },
      box6: {
        title: "西瓜视频2021年新年礼盒",
        items: ["糖果饼干", "新春红包", "笔墨", "定制春联", "公仔", "口罩"],
      },
      box7: {
        title: "喜马拉雅2021中秋礼盒",
        items: [
          "外包装",
          "月饼包装",
          "定制月饼",
          "定制餐具",
          "流沙摆件",
          "徽章",
        ],
      },
      box8: {
        title: "大力智能2021年新年礼盒",
        items: [
          "大力智能学习灯",
          "大力智能手写板",
          "定制红包",
          "水彩笔",
          "灯筒",
          "帆布袋",
        ],
      },
    },
    culture_derivative: {
      tab1: {
        title: "于谦入驻抖音",
        sub_title: '"头号玩家入驻抖音"',
        icon: "/cases/culture_derivative/tab1@2x.png",
        icon_hovered: "/cases/culture_derivative/tab1-hovered@2x.png",
        content:
          "德云社“头号玩儿家”于谦入驻抖音，只为将“玩儿”进行到底。说唱、废话文学和抖音热曲，在抖音特效的加持下“笑果”不俗，引来网友关注。专为本次活动定制的周边有包括布艺灯、电脑包、耳机套、木纹书灯和手机壳等好玩有趣的产品。",
      },
      tab2: {
        title: "IP 公仔",
        sub_title: "潮玩可爱周边公仔",
        icon: "/cases/culture_derivative/tab2@2x.png",
        icon_hovered: "/cases/culture_derivative/tab2-hovered@2x.png",
        content:
          "“抖音”“快手”两大IP形象基础上，将动物、潮流、新年等元素加入设计，推出棒棒糖、东北虎、电音、飞机、虎年等众多主题的系列公仔，可以组成盲盒、伴手礼等多种形式。",
      },
      tab3: {
        title: "老画新用点心礼盒",
        sub_title: "水墨人物出画来",
        icon: "/cases/culture_derivative/tab3@2x.png",
        icon_hovered: "/cases/culture_derivative/tab3-hovered@2x.png",
        content:
          "独具匠心的点心礼盒包装设计，将水墨风格的传统中国画结合卡通形象和主IP，化用为设计元素和构图，即富含传统文化内涵，又充满童趣和现代简约设计。包括刘贯道《梦蝶图》、陆治《竹林长夏图》以及阎立本《十八学士图》等。",
      },
    },
    solar_terms: {
      title: "二十四节气小能",
      term1: {
        title: "立春",
        sub_title: "LICHUN",
        url: "/cases/solar_terms/1.png",
      },
      term2: {
        title: "雨水",
        sub_title: "YUSHUI",
        url: "/cases/solar_terms/2.png",
      },
      term3: {
        title: "惊蛰",
        sub_title: "JINGZHE",
        url: "/cases/solar_terms/3.png",
      },
      term4: {
        title: "春分",
        sub_title: "CHUNFEN",
        url: "/cases/solar_terms/4.png",
      },
      term5: {
        title: "清明",
        sub_title: "QINGMING",
        url: "/cases/solar_terms/5.png",
      },
      term6: {
        title: "谷雨",
        sub_title: "GUYU",
        url: "/cases/solar_terms/6.png",
      },
      term7: {
        title: "立夏",
        sub_title: "LIXIA",
        url: "/cases/solar_terms/7.png",
      },
      term8: {
        title: "小满",
        sub_title: "XIAOMAN",
        url: "/cases/solar_terms/8.png",
      },
      term9: {
        title: "芒种",
        sub_title: "MANGZHONG",
        url: "/cases/solar_terms/9.png",
      },
      term10: {
        title: "夏至",
        sub_title: "XIAZHI",
        url: "/cases/solar_terms/10.png",
      },
      term11: {
        title: "小暑",
        sub_title: "XIAOSHU",
        url: "/cases/solar_terms/11.png",
      },
      term12: {
        title: "大暑",
        sub_title: "DASHU",
        url: "/cases/solar_terms/12.png",
      },
      term13: {
        title: "立秋",
        sub_title: "LIQIU",
        url: "/cases/solar_terms/13.png",
      },
      term14: {
        title: "处暑",
        sub_title: "CHUSHU",
        url: "/cases/solar_terms/14.png",
      },
      term15: {
        title: "白露",
        sub_title: "BAILU",
        url: "/cases/solar_terms/15.png",
      },
      term16: {
        title: "秋分",
        sub_title: "QIUFEN",
        url: "/cases/solar_terms/16.png",
      },
      term17: {
        title: "寒露",
        sub_title: "HANLU",
        url: "/cases/solar_terms/17.png",
      },
      term18: {
        title: "霜降",
        sub_title: "SHUANGJIANG",
        url: "/cases/solar_terms/18.png",
      },
      term19: {
        title: "立冬",
        sub_title: "LIDONG",
        url: "/cases/solar_terms/19.png",
      },
      term20: {
        title: "小雪",
        sub_title: "XIAOXUE",
        url: "/cases/solar_terms/20.png",
      },
      term21: {
        title: "大雪",
        sub_title: "DAXUE",
        url: "/cases/solar_terms/21.png",
      },
      term22: {
        title: "冬至",
        sub_title: "DONGZHI",
        url: "/cases/solar_terms/22.png",
      },
      term23: {
        title: "小寒",
        sub_title: "XIAOHAN",
        url: "/cases/solar_terms/23.png",
      },
      term24: {
        title: "大寒",
        sub_title: "DAHAN",
        url: "/cases/solar_terms/24.png",
      },
    },
    contact_us: {
      official_phone_title: "官方电话",
      official_phone: "400-888-3905",
      beijing_site: "北京总部",
      beijing_contact_person: "北京联系人：周先生",
      beijing_phone: "联系人电话：133 6331 2878",
      shanghai_site: "上海分公司",
      shanghai_contact_person: "上海联系人：杨先生",
      shanghai_phone: "联系人电话：131 2238 5882",
      shenzhen_site: "深圳分公司",
      shenzhen_contact_person: "深圳联系人：马女士",
      shenzhen_phone: "联系人电话：178 0105 9665",
      visit: "立即访问",
    },
    creative_design: {
      title: "棠镇全景图",
      content:
        "棠镇布局依照唐朝都城长安城为参考，分为东西南北市，一市一季，分别对应春夏秋冬四季，四季各有应时的美食，春有樱花、夏有豆糕、秋有螃蟹、冬有柿饼，应时而食。",
    },
  },
  portfolio: {
    title1: "得物2022年新年礼盒",
    desc1:
      "Woo虎起飞。结合网络热点谐音词汇，创新地将虎年形象与《舒克与贝塔》结合，充满童趣和新年美好祝愿，寓意虎年好运起飞、业绩起飞。",
    title2: "宇信科技2020年中秋礼盒",
    desc2:
      "花好月圆，长相伴，共守护。中秋礼盒以“守护”为主题，主画面结合科幻与卡通形象，寓意“守护你的星河灿烂”即传递出了科技感，又寄托了中秋的深邃思念与祝福。",
    title3: "磁力引擎2021年端午礼盒",
    desc3:
      "一往无前，发现新世界。主产品为木质放映机，既体现了快手“拥抱每一种生活”的Slogan，也寓意着乘坐端午磁力龙舟，一往无前地发现、记录和传承。",
  },
  new_arrivals: {
    descriptions: [
      "有趣的创意、出色的设计、完善的供应链",
      "季节、心情、场景、节日等多种主题",
      "包含服饰、3C数码、食物等多种品类",
    ],
  },
  about_us: {
    us: "我们",
    us_english: "About Us",
    vision_title: "企业愿景",
    vision_description: "让每家公司都拥有专属的定制礼品",
    values_title: "企业价值观",
    values_description: "客户第一 员工第二 老板第三",
    company_intro_p1:
      "北京春湘科技有限公司于2018年成立，19年推出自有品牌“铜棠有礼”，专注服务于企业客户，主营IP定制产品、节日福利礼盒、日常营销礼品、非遗文化创意设计等，并可提供全案定制及仓储物流配送服务；拥有丰富的定制礼品产品线，完善的服务流程，强大的供应链体系，年轻高效的服务团队，创意满满的设计大咖，成立以来飞速发展，21年已在上海、深圳分别设立分公司，客户覆盖互联网、IT、在线教育、金融、游戏、地产等不同行业，以优质的服务，赢得了新老客户的众多好评。",
    company_intro_p2:
      "公司设计团队拿过德国红点奖，为企业客户提供专属的设计方案及素材；已通过ISO9001质量体系认证、售后服务体系认证，设立客户支持团队，用专业高效的服务流程，提供客户需要的服务方案；建立了遍布全国的多元化供应商体系，并在多家生产企业注资，保障生产供应的及时性、稳定性；在全国的五个大区搭建了现代化供应链仓库，满足客户的存放、库存管理及配送需求。",
    cert1: "营业执照",
    cert2: "食品经营许可证",
    cert3: "质量管理体系认证证书（中英文）",
    cert4: "售后服务认证证书（中英文）",
    location: {
      beijing: {
        title: "北京总部",
        address: "北京市朝阳区望京街道佳境天城A座1905",
        coordinate: [116.476474, 39.980428],
      },
      shanghai: {
        title: "上海分公司",
        address: "上海市长宁区仙霞路350号科创楼413",
        coordinate: [121.397667, 31.204108],
      },
      shenzhen: {
        title: "深圳分公司",
        address: "广东省深圳市福田区沙头街道泰然科技园201栋东座419",
        coordinate: [114.027456, 22.534893],
      },
    },
    detail_us: {
      jianjie: {
        title: "简介",
        jianjie_p1:
          "北京春湘科技有限公司成立于2018年，是国内领先的企业礼品定制服务商。",
        jianjie_p2: "2019年推出自有品牌“铜棠有礼”。",
        jianjie_p3: "2021年在上海、深圳分别设立分公司。",
        jianjie_p4: "2022年礼品定制商城“一点礼至”正式上线。",
        jianjie_p5: "官网地址：https://www.tongtanggift.com/",
        jianjie_p6: "官方热线：400-888-3905",
        jianjie_p7: "邮箱：market@hxtongtang.com",
      },
      pinpai: {
        title: "旗下品牌",
        tongtang: {
          content:
            "“铜棠有礼”是北京春湘科技的线下业务品牌，专注于为KA级企业客户提供全方位的企业礼品解决方案。业务范围覆盖IP定制产品、节日福利礼盒、日常营销礼品、非遗文化创意设计等全案定制及仓储物流配送服务等。以丰富的定制礼品产品线、高效完善的服务流程、强大的供应链体系、以及优质的设计能力，服务了包括字节跳动、快手、百度、得物、喜马拉雅、光大银行、邦泰等在内的众多世界500强企业及行业头部客户，覆盖互联网、IT、金融、游戏、地产等不同行业。",
          image_url: "/join_us/detail_us/tongtang_img.png",
        },
        yidianlizhi: {
          content:
            "“一点礼至”是北京春湘科技的线上业务品牌，专注于通过数字化平台的手段为广大中小型企业客户，提供丰富、快捷、高效在线礼品定制服务。汇聚海量的自营定制产品，品类齐全，基本覆盖国内目前可支持定制的所有品类商品，包括服装、工艺品、印刷品、数码3C等。使用场景覆盖员工关怀、企业周边、活动物资、商务礼赠等多种企业用途，可实现在线DIY、准确核价、快速下单、精准发货、框架结算等采购全流程数字化能力。",
          image_url: "/join_us/detail_us/yidianlizhi_img.png",
        },
      },
      guimo: {
        title: "公司规模",
        p1: "公司业务在不断的快速发展下，逐步成立设计中心、供应链管理中心、营销中心、产品中心、综合管理中心等，2021年在上海、深圳分别设立分公司。基于企业礼品定制，实现企业服务深度定制化，提供一站式的全案定制解决方案。",
        p2: "员工数量50+",
        p3: "服务客户150+",
        p4: "知识产权7+",
        p5: "2021年营业额1亿",
      },
      youshi: {
        title: "优势能力",
        p1_title: "专业出众的设计能力",
        p1_content:
          "汇聚众多优秀设计师，设计团队曾获德国红点奖，为企业客户提供专属的创意设计方案提供专业能力保障。",
        p2_title: "响应及时的客户服务",
        p2_content:
          "已通过ISO9001质量体系认证、售后服务体系认证等，同时设立客户服务团队，以标准、专业的服务流程，为客户提供响应及时的星级管家服务。",
        p3_title: "多元化的供应链体系",
        p3_content:
          "建立了遍布全国的供应商体系，并在多家生产企业注资，保障生产供应的及时性与稳定性。",
        p4_title: "完善的仓储配送服务",
        p4_content:
          "在全国五个大区搭建了现代化供应链仓库，满足客户的存放、库存管理及配送需求。",
      },
      yuanjing: {
        title: "企业愿景",
        content: "一个更专业的定制伙伴",
      },
      shiming: {
        title: "企业使命",
        content: "让每家公司都拥有专属的定制礼品",
      },
      jiazhiguan: {
        title: "企业价值观",
        content:
          "诚信勤俭，立德立业；\n" +
          "活跃进取，勇于担当；\n" +
          "互助学习，砥砺奋进；\n" +
          "自我驱动，实干灵活；\n" +
          "革新创造，高标高效。",
      },
      fuwulinian: {
        title: "服务理念",
        content: "客户第一，品牌至上，一切以树立良好企业形象为根基",
      },
      zizhi: {
        title: "企业资质",
        content:
          "先后获得ISO9001质量管理体系认证、售后服务认证、信息安全管体系认证以及德国红点奖、法国INNODESIGN PRIZE国际创新设计大奖等。",
        image_url1: "/join_us/detail_us/yingyezhizhao.png",
        image_url2: "/join_us/detail_us/anquantili_zh.png",
        image_url3: "/join_us/detail_us/anquantili_en.png",
        image_url4: "/join_us/detail_us/hongdian_1.png",
        image_url5: "/join_us/detail_us/hongdian_2.png",
      },
    },
  },
  join_us: {
    join: "加入",
    join_english: "Join Us",
    recruit_beijing: "北京",
    recruit_shenzhen: "上海",
    work_location_title: "工作地点",
    publish_date_title: "发布时间",
    view_detail: "查看详情",
    base_salary_title: "基本薪资",
    position_responsibility: "岗位职责",
    position_requirement: "任职要求",
    contact_title: "投递方式",
    contact: "简历投递邮箱：hr@hxtongtang.com ",
    benefit:
      "五险一金，补充医疗，带薪年假，部分带薪病假；年度体检，节日福利，住房补贴；丰富的业余团建生活；频繁的企业内部培训，外部培训及进修。",
    office: {
      intro:
        "温馨、整洁、明亮的办公环境；众多的公司自制手办、公仔、摆件让你的工位充满活力；完善的会议设备让远程沟通高效、便捷；随业务和团队扩张，即将搬赴更广阔的办公室。",
      office_area: "办公区",
      meeting_area: "会议室",
      display_area: "展示区",
      selective: "单品",
      toy: "公仔",
      gift_box: "礼盒",
    },
    recruit: {
      beijing_pos1: {
        title: "项目经理",
        location: "北京",
        publish_date: "2023.03.01",
        base_salary: "6-9k",
        responsibilities: [
          "1. 根据客户需求，跟进相关产品的打样工作，为竞标做准备；",
          "2.参与现场竞标；",
          "3.负责中标项目的整体项目规划，推进并执行；",
          "4负责整体项目管理，积极与客户沟通项目情况，做好客户预期，项目关键节点的把控及信息同步工作；",
          "5.跟进大货生产及质检情况；",
          "6.跟进产品组装及验收；",
          "7.跟进物流及发货，售后问题；",
          "8.负责其他领导交办的事情。",
        ],
        requirements: [
          "1.大专或以上学历，有礼品类采销工作背景者优先",
          "2.对数据敏感、逻辑清晰、严谨，应变和抗压能力强；",
          "3.具备良好的组织协调和沟通能力，学习能力和团队合作精神强，具有敬业、吃苦精神；",
          "4.有良好的职业道德，敬业精神和团队合作精神。",
        ],
      },
      beijing_pos2: {
        title: "产品创意专家",
        location: "北京",
        publish_date: "2023.03.01",
        base_salary: "16-25K",
        responsibilities: [
          "1、主持设计部的设计工作，把握方案设计理念，把握整体设计风格，并有独立完成设计的能力；",
          "2、准确把握客户要求，指导部门设计师执行设计工作；",
          "3、负责把控创意设计风格及进度，协助项目部及策划人员共同制定客户计划的整体解决方案，并形成统一策略；",
          "4、具备广告策略思维，有实力参与策划方案（大型礼品项目、创意设计项目）的创意构思；",
          "5、参与公司重要客户的谈判，精准地向客户阐述出设计理念，赢得客户信服；",
          "6、负责对部门设计人员进行专业培训和指导，提升整体设计人员的专业水平。",
        ],
        requirements: [
          "1、本科及以上学历，专业院校美术、艺术类等专业背景；",
          "2、具备三年以上礼品设计部门管理经验优先；具有深厚的美术功底，优秀的设计创作能力，良好的艺术感觉和创意能力；",
          "3、具较高的视觉文化底蕴和艺术造诣，有准确的品牌形象把握能力、丰富的互动设计经验、平面设计经验、后期制作经验，能独立做出概念设计方案，具有较好的色彩、视觉性；",
          "4、对广告策略创意策略有较强的领悟能力和一定的把握能力，带领并指导品牌创意构思及执行，保证并监督创意作品的质量；",
          "5、具有较强的团队合作精神，能承受较大的工作压力。",
        ],
      },
      beijing_pos3: {
        title: "高级平面设计师",
        location: "北京",
        publish_date: "2023.03.01",
        base_salary: "15-20K",
        responsibilities: [
          "1、以平面设计为主，主要负责文创产品和包装的原创定制方案设计及微定制设计工作，具备手绘插画等其它技能择优录用。",
          "2、关注、研究设计潮流及圈内文化，了解行业动态，熟悉礼品定制设计流程。",
          "3、深刻理解并分析客户文化内涵，结合客户需求，挖掘创意亮点，提出新颖、创造性的设计思路或理念，参与制定策划方案。",
          "4、与本部门及相关部门人员保持良好沟通，负责或协助团队管理相关工作。",
        ],
        requirements: [
          "1、正规艺术院校设计专业、美术等相关专业毕业，重本学校统招本科或以上学历毕业，三年以上礼品/包装/平面设计相关工作经验。",
          "2、熟练使用photoshop、illustrator等常用设计制作软件；具有扎实的美术功底、独特的视觉表现力及较高的审美能力；有图案设计、手绘设计及插画能力或有礼品设计优秀成功案例者优先考虑。",
          "3、具备良好的沟通能力和文字表达能力，思维敏捷、表现能力强，有产品原创思维和经验，具备较强的创意、策划能力，能够独立对接并完成项目组相关设计任务。",
          "4、有责任心，工作效率高，具有团队合作精神及良好的工作态度，能承受较强的工作压力，热爱艺术和创意设计。",
          "5、熟悉各类常见产品的生产工艺，对于项目创意及策划方案的质量、进度有极强的掌控力和执行力，有一定的团队管理经验。",
        ],
      },
      beijing_pos4: {
        title: "平面设计师",
        location: "北京",
        publish_date: "2023.03.01",
        base_salary: "6-11K",
        responsibilities: [
          "1、负责产品图案、材料、工艺、颜色设计、包装设计、产品生产文件输出；",
          "2、负责品牌和视觉传达的创意、设计、延展；",
          "3、公司IP图库开发，包括IP设计、品牌色、元素、图案、整合排版、字体设计等；",
          "4、IP形象授权衍生品、文创周边的平面设计、包装设计工作；",
          "5、实时把握设计的流行趋势，提出崭新的创意策略；",
          "6、完成上级下达的其他相关平面设计工作。",
        ],
        requirements: [
          "1、大专及以上学历，可接受应届毕业生，会3D建模优先，视觉传达、美术、设计类、动漫专业优先；",
          "2、能够精通Photoshop，lllustrator，AI， MAX, SAI，C4D等设计软件；",
          "3、较强的美术功底、理解分析、创意设计能力和色彩搭配能力；有原创素材能力，会矢量插画，对构成、线条、色彩等把控能力稳定，有提炼再创作能力；",
          "4、基础扎实，创新思维，快速反应，工作积极、有团队意识，热爱绘画与设计。",
          "5、有手绘功底扎实优先，有参与过IP开发推广者优先。",
          "6、能够独立进行包装设计和VI设计；有成熟的个人作品，热爱传统文化。",
        ],
      },
      beijing_pos5: {
        title: "三维渲染师",
        location: "北京",
        publish_date: "2023.03.01",
        base_salary: "9-13K",
        responsibilities: [
          "1、团队协作完成项目提案中的3D渲染部分；",
          "2、以包装物（礼盒类）和客户IP两大类三维建模渲染需求为主。",
        ],
        requirements: [
          "1、熟练使用C4D、OC渲染器等软件工具；",
          "2、具备良好的场景设计、材质、灯光的处理能力；",
          "3、具备较好的美术基础，对镜头美感有较好的把控，能独立完成建模、渲染；",
          "4、团队成员间善于沟通，有较好的团队协作能力和理解能力；",
          "5、平面设计能力强的优选。",
        ],
      },
      shenzhen_pos1: {
        title: "产品创意专家",
        location: "上海",
        publish_date: "2023.03.01",
        base_salary: "16-25K",
        responsibilities: [
          "1、主持设计部的设计工作，把握方案设计理念，把握整体设计风格，并有独立完成设计的能力；",
          "2、准确把握客户要求，指导部门设计师执行设计工作；",
          "3、负责把控创意设计风格及进度，协助项目部及策划人员共同制定客户计划的整体解决方案，并形成统一策略；",
          "4、具备广告策略思维，有实力参与策划方案（大型礼品项目、创意设计项目）的创意构思；",
          "5、参与公司重要客户的谈判，精准地向客户阐述出设计理念，赢得客户信服；",
          "6、负责对部门设计人员进行专业培训和指导，提升整体设计人员的专业水平。",
        ],
        requirements: [
          "1、本科及以上学历，专业院校美术、艺术类等专业背景；",
          "2、具备三年以上礼品设计部门管理经验优先；具有深厚的美术功底，优秀的设计创作能力，良好的艺术感觉和创意能力；",
          "3、具较高的视觉文化底蕴和艺术造诣，有准确的品牌形象把握能力、丰富的互动设计经验、平面设计经验、后期制作经验，能独立做出概念设计方案，具有较好的色彩、视觉性；",
          "4、对广告策略创意策略有较强的领悟能力和一定的把握能力，带领并指导品牌创意构思及执行，保证并监督创意作品的质量；",
          "5、具有较强的团队合作精神，能承受较大的工作压力。",
        ],
      },
      shenzhen_pos2: {
        title: "项目经理",
        location: "上海",
        publish_date: "2023.03.01",
        base_salary: "底薪6-9k",
        responsibilities: [
          "1. 根据客户需求，跟进相关产品的打样工作，为竞标做准备；",
          "2.参与现场竞标；",
          "3.负责中标项目的整体项目规划，推进并执行；",
          "4.负责整体项目管理，积极与客户沟通项目情况，做好客户预期，项目关键节点的把控及信息同步工作；",
          "5.跟进大货生产及质检情况；",
          "6.跟进产品组装及验收；",
          "7.跟进物流及发货，售后问题；",
          "8.负责其他领导交办的事情。",
        ],
        requirements: [
          "1.大专或以上学历，有礼品类采销工作背景者优先；",
          "2.对数据敏感、逻辑清晰、严谨，应变和抗压能力强； ",
          "3.具备良好的组织协调和沟通能力，学习能力和团队合作精神强，具有敬业、吃苦精神；",
          "4.有良好的职业道德，敬业精神和团队合作精神。",
        ],
      },
      shenzhen_pos3: {
        title: "三维渲染师",
        location: "深圳",
        publish_date: "2022.03.01",
        base_salary: "9 - 13K",
        responsibilities: [
          "1. 团队协作完成项目提案中的3D渲染部分；",
          "2. 以包装物（礼盒类）和客户IP两大类三维建模渲染需求为主。",
        ],
        requirements: [
          "1. 熟练使用C4D、OC渲染器等软件工具；",
          "2. 具备良好的场景设计、材质、灯光的处理能力；",
          "3. 具备较好的美术基础，对镜头美感有较好的把控，能独立完成建模、渲染；",
          "4. 团队成员间善于沟通，有较好的团队协作能力和理解能力；",
          "5. 平面设计能力强的优选。",
        ],
      },
    },
  },
};

export default strings;
