import Service from "./api";

//查询首页分类内容详情
export const queryGoodsSimpleInfoList = (params) => {
  return Service({
    url: `/home/queryGoodsSimpleInfoList/?categoryId=${params.categoryId}${
      params.pageSize ? `&pageSize=${params.pageSize}` : ""
    }${params.pageNum ? `&pageNum=${params.pageNum}` : ""}`,
    method: "get",
  });
};
//查询首页分类tab
export const queryHomeCategoryList = (params) => {
  return Service({
    url: `/home/queryHomeCategoryList`,
    method: "get",
  });
};
